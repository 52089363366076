$main-color: #00b285;
$skew-angle: -10deg;
@mixin transform($transforms) {
  -webkit-transform: $transforms;
  -moz-transform: $transforms;
  -ms-transform: $transforms;
  transform: $transforms;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  //   50% {
  //     opacity: 0;
  //   }
  100% {
    opacity: 1;
  }
}
@keyframes bgfadeIn {
  0% {
    background: #f3f3df;
  }
  100% {
  }
}

html,
body {
  height: Rheight(300, 100);
  width: 100vw;
  position: relative;
}

body {
  animation: 2s ease bgfadeIn backwards;

  background: repeating-linear-gradient(
    -75deg,
    #f3f3df 0%,
    #f3f3df 58%,
    #f3f3df 58%,
    #f3f5ea 100%
  );
  transition: background 1s;
}


$h: 5vw;
//superclass
%content {
  animation: none;
  //animation: 1.5s ease fadeIn backwards; //6.5
}


a,
a:visited,
a:active {
  .menu-item {
    color: #f3f3df;
  }
}

@function height($height) {
  $result: $height * 100/1080;
  @return $result * 1vh;
}

@function Rheight($height, $width) {
  $result: $width * 100/1920 * ($height/$width);
  @return $result * 1vw;
}

@function width($width) {
  $result: $width * 100/1920;
  @return $result * 1vw;
} 

.joshtext {
  @extend %content;

  position: absolute;
  //width: 42000vw/1920vw;
  //height: 5600vw/1080vh;

  //width(496) * (250/496);
  left: width(100); //5.2vw;
  top: Rheight(150, 100); //width(100) * (150/100);

  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: width(48);
  line-height: width(56);

  color: #3e3e32;
}

@keyframes upanddown {
  25% {
    transform: translateY(-10px);
  }
  50% {
    transform: translateY(0px);
  }

  75% {
    transform: translateY(10px);
  }
}

%maintext {
  @extend %content;

  position: absolute;

  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: width(36);
  line-height: width(42);
  //animation-name: appear;
  //animation-duration: 1.2s;
  ////animation-timing-function: linear;
  color: #3e3e32;
}

.point {
  @extend %content;

  position: absolute;
  //width: 42000vw/1920vw;
  //height: 5600vw/1080vh;
  left: width(360); //5.2vw;
  top: Rheight(-55, 360); //height(-55); //9.25vh;

  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: width(48);
  line-height: width(56);
  animation-name: upanddown;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}
.england {
  @extend %maintext;
  width: width(546);
  height: Rheight(126, 546); // width(546) * (126/546); // height(126);
  left: width(100);
  top: Rheight(526, 100); // width(100) * (526/100); // height(526); //526
  //animation-delay: 2s;
}

.yale {
  @extend %maintext;
  width: width(681);
  height: Rheight(84, 681);
  left: width(1158);
  top: Rheight(858, 1158); //526
  //animation-delay: 3s;
  text-align: right;
}

.queencaption {
  @extend %maintext;

  position: absolute;
  width: width(550);
  height: Rheight(28, 550);
  left: width(1343);
  top: Rheight(638, 1343);
  font-size: width(24);
  //animation-delay: 4s;
}

%pictures {
  @extend %content;
  position: absolute;
  //animation-name: appear;
  //animation-duration: 1.5s;
  //animation-timing-function: linear;
}

.queen {
  @extend %pictures;
  width: width(496);
  height: width(496) * (250/496);
  left: width(1343);
  top: Rheight(407, 1343);
  //animation-delay: 4s;
}
.ycba {
  @extend %pictures;

  width: width(496);
  height: width(496) * (405/496);
  left: width(100);
  top: Rheight(750, 100);
  //animation-delay: 5s;
}

.wapoPic {
  @extend %pictures;
  width: width(642);
  height: Rheight(386, 642);
  left: width(1103);
  top: Rheight(1199, 1103);
  //animation-delay: 5s;
}
.kikiLogo {
  @extend %pictures;
  width: width(250);
  height: width(250) * (248.98/250);
  left: width(61);
  top: Rheight(2420, 61);
  //   border-radius: 60px;
}

.helixLogo {
  @extend %pictures;
  width: width(259.56);
  height: width(250) * (248.98/250);
  left: width(61);
  top: Rheight(2825, 61);
  border-radius: 60px;
}

.ron {
  @extend %pictures;
  width: width(500);
  height: width(500) * (217/500);
  left: width(1360);
  top: Rheight(2420, 1360);
}
.me {
  @extend %pictures;
  width: width(360.41);
  height: width(360.41) * (350/360.41);
  left: width(781);
  top: Rheight(2769, 781);
}

.linkedin {
  @extend %pictures;
  width: width(52.32);
  height: Rheight(50, 52.32);
  left: width(833);
  top: Rheight(3142, 833);
  margin-bottom: 3vh;
}

.medium {
  @extend %pictures;
  width: width(63.07);
  height: Rheight(50, 63.07);
  left: width(929);
  top: Rheight(3142, 929);
  margin-bottom: 3vh;
}

.email {
  @extend %pictures;
  width: width(66);
  height: Rheight(66, 66);
  left: 53.96%;
  top: Rheight(3133.5, 1500);
  margin-bottom: 3vh;
}

.shh {
  //   @extend %pictures;
  //   width: width(401);
  //   height: height(386);

  @extend %maintext;

  position: absolute;
  width: width(150);
  height: Rheight(150, 150);

  font-size: width(200);

  left: width(1678); //1568
  top: Rheight(2773-14, 1678);
}

.ironmanPic {
  @extend %pictures;
  width: width(416);
  height: width(416) * (687/416);
  left: width(256);
  top: Rheight(1559, 256);
  //animation-delay: 5s;
}

.wapo {
  @extend %maintext;

  width: width(681);
  height: width(681) * (42/681);
  left: width(100);
  top: Rheight(1345, 100);
  //animation-delay: 5s;
}

.ironman {
  @extend %maintext;

  width: width(681);
  height: width(84);
  left: width(748);
  top: Rheight(1720, 748);
  text-align: right;
}

.but {
  @extend %maintext;
  width: width(350);
  height: width(42);
  left: width(835);
  top: Rheight(2540, 835);
}

.founder {
  @extend %maintext;
  width: width(700);
  height: Rheight(105, 700);
  left: width(712);
  top: Rheight(2550, 712);

  font-weight: bold;
  font-size: width(90);
  line-height: Rheight(105, 90);
}
.past {
  @extend %maintext;
  width: width(681);
  height: Rheight(84, 681);
  left: width(61);
  top: Rheight(2154, 61);

  font-weight: bold;
  font-size: width(72);
  line-height: width(84);
}
.future {
  @extend %maintext;
  width: width(209);
  height: Rheight(84, 209);
  left: width(1639);
  top: Rheight(2154, 1639);

  font-weight: bold;
  font-size: width(72);
  line-height: width(84);
}

.kiki {
  @extend %maintext;
  width: width(700);
  height: Rheight(84, 650);
  left: width(61);
  top: Rheight(2304-25, 61);
}

.ondeck {
  @extend %maintext;
  width: width(735);
  height: Rheight(130, 735);
  left: width(1125);
  top: Rheight(2304-25, 1125);
  text-align: right;
}
.helix {
  @extend %maintext;
  width: width(750);
  height: Rheight(42, 750);
  left: width(61);
  top: Rheight(3061, 61);
}

.new {
  @extend %maintext;
  width: width(600);
  height: Rheight(42, 524);
  left: width(1336-76);
  top: Rheight(3061, 1336);
  margin-bottom: 3vh;
  text-align: right;
}

%line {
  @extend %content;
  position: absolute;
  stroke-dasharray: 2000;
  stroke-dashoffset: 2000;
  transition-timing-function: ease;
}
@keyframes dash {
  to {
    stroke-dashoffset: 1;
  }
}

.line1 {
  @extend %line;

  position: absolute;
  width: width(151);
  height: width(151) * (300/151); // height(300);
  left: width(237);
  top: width(237) * (250/237); //height(250);

  transition: stroke-dashoffset 3s;

  // animation: 2s dash 2s ease forwards;
}

.line2 {
  @extend %line;

  position: absolute;
  width: width(619.47);
  height: Rheight(282.87, 619.47);
  left: width(571.03);
  top: Rheight(663.63, 571.03);

  transition: stroke-dashoffset 2.5s;
}

.line3a {
  @extend %line;
  width: width(1101);
  height: Rheight(391, 1101);
  left: width(447);
  top: Rheight(981, 447);
  // stroke-dasharray: 1000;
  transition: stroke-dashoffset 1.5s;
}

.line4 {
  @extend %line;

  width: width(724);
  height: Rheight(287, 724);
  left: width(340);
  top: Rheight(1430, 340);

  transition: stroke-dashoffset 2s;
}

.line5 {
  @extend %content;
  position: absolute;
  // border: 6px solid #6693ab;
  width: width(164);
  height: Rheight(729.5, 162);
  left: width(961);
  top: Rheight(1840.42, 961);
  stroke-dasharray: 2000;
  stroke-dashoffset: 2000;
  transition: stroke-dashoffset 3s;
}

.line8 {
  @extend %line;
  width: width(388.41);
  height: Rheight(186.62, 388.41);
  left: width(320);
  top: Rheight(2499.96, 320);

  transition: stroke-dashoffset 5s;
}

.line9 {
  @extend %line;

  width: width(334.64);
  height: Rheight(298.12, 334.64);
  left: width(449);
  top: Rheight(2756.44, 449);
  transition: stroke-dashoffset 5s;
  transition-delay: 1s;
}

.line10 {
  @extend %line;

  width: width(115);
  height: Rheight(153.5, 115);
  left: width(1230);
  top: Rheight(2502, 1230);
  transition: stroke-dashoffset 5s;
  transition-delay: 2s;
}

.line11 {
  @extend %line;

  width: width(244);
  height: Rheight(307, 244);
  left: width(1207);
  top: Rheight(2754, 1207);
  transition: stroke-dashoffset 5s;
  transition-delay: 3s;
}

@keyframes appear {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
