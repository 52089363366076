$main-color: #00b285;
$skew-angle: -10deg;
@mixin transform($transforms) {
  -webkit-transform: $transforms;
  -moz-transform: $transforms;
  -ms-transform: $transforms;
  transform: $transforms;
}
@keyframes sweep {
  0% {
  }
  2% {
    transform: rotate(-5deg);
  }
  4% {
    transform: rotate(5deg);
  }
  6% {
    transform: rotate(-5deg);
  }
  8% {
    transform: rotate(5deg);
  }
  10% {
    transform: rotate(-5deg);
  }
  12% {
    transform: rotate(5deg);
  }
  14% {
    transform: rotate(0deg);
  }
  30% {
    transform: rotate(0deg);
  }
  32% {
    transform: rotate(10deg);
  }
  34% {
    transform: rotate(-10deg);
  }
  36% {
    transform: rotate(10deg);
  }
  38% {
    transform: rotate(-10deg);
  }
  40%{
      transform: rotate(0deg)
  }
  50% {
    transform: scale(1);
  }
  64%{
    transform: scale(1)
  }
  68%{
    transform: scale(0.8)
  }
  70% {
    transform: scale(10);
  }

  72% {
    transform: rotate(15deg) scale(30) scaleY(2);
  }
  100% {
    transform: rotate(15deg) scale(30) translateX(3.2vw) translateY(-1vh) scaleY(2);
    position: fixed;
  }
  
  
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }
  20%{opacity: 0}; 
  50%{opacity: 0};
  90% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes bgfadeIn {
  0% {
    background:#f3f5ea;
  }
  100% {
  }
}

@keyframes blinkTitle{
    0%{
        color: #f3f3df;
        text-shadow: 0.5vw 0.5vw#ebc31d;
    }
    49%{
        color: #f3f3df;
        text-shadow: 0.5vw 0.5vw#ebc31d;
    }
    50%{
        color: #ebc31d;
        text-shadow: 0.5vw 0.5vw #f3f3df;
    }
    
    100%{
        color: #ebc31d;
        text-shadow: 0.5vw 0.5vw #f3f3df;
    }
}


html,
body {
  height: 100%;
  width: 100%;
  position: fixed;
}

body {
  //animation: 18s ease bgfadeIn backwards;
  
  background: repeating-linear-gradient(
    -75deg,
    #f47748 0%,
    #f47748 58%,
    #f3f3df 58%,
    #f3f5ea 100%,
   
  );
  transition: background 1s; 
}

.cool-box {
  animation: 4s ease-in sweep both;
  z-index: 3;
  position: absolute;
  left: 35vw;
  top: 35vw;
  width: 7vw;
  height: 7vw;
  background-color: #f47748;
  transition: background-color 1s, transform 1s;
}

$h: 5vw;
//superclass
%content {
    animation: 10s ease fadeIn backwards;
}

//superclass 
%useless-labs { 
  animation: 0s ease fade-in backwards;
  width: 4vw; 
  height: 4vw;
  //white-space: nowrap;
  z-index: 2;
  clear: right;
  position: absolute;
  padding-top: 0.4vw;
  padding-left: 0.0vw; 
  //padding-right: 20px;
  //padding-bottom: 0.5vh;
  left: 0;
  top: 0;
  color:#f3f3df;
  text-shadow: 0.5vw 0.5vw #e7bb0c;
  -webkit-text-stroke: 0.04vw;
  -webkit-text-stroke-color:black;
  margin-top: 4vw;
  margin-left: 3vw;
  border-style: solid; 
  border-color:#f47748;
  border-width: 0.5vw;
  text-align: justify;
  text-justify: auto;
  font-family: "Roboto", sans-serif;
  font-size: 4.2vw;
  font-style: normal;
  font-variant: normal;
  font-weight: 920;
  line-height: 1vw;
  background: #f47748;
  transform: scale(1);
  box-shadow: -0.5vw -0.5vw #ebc31d;
}



.useless-labs {
  @extend %useless-labs;
}

.useless-labs:hover{
  @extend %useless-labs;
  .text {
    animation: 0.3s linear blinkTitle infinite
  }
  
}

.menu-title {
    z-index: 4;
    animation: 0s ease fade-in backwards;
    height: 3vw;
    width: 10vw;
    margin: 2vw;
    background: repeating-linear-gradient(90deg, #f4774800 0%, #f4774800 30%, #ebc31d  30%, #ebc31d 100%);
    position: absolute;
    right: 0;
    top: 0;
    -webkit-text-stroke: 0.04vw;;
    -webkit-text-stroke-color: black;
    padding-top: 0.4vw;
    margin-top: 4vw; 
    margin-right:  2vw; 
    display: block;
    font-family: "Roboto", sans-serif;
    font-size: 2.5vw;
    font-style: normal;
    font-variant: normal;
    font-weight: 920;
    line-height: 1.7vw;
    color:#f3f3df;  
    text-align: right; 
    

    


    .menu-item{
      font-size: 2vw;
      text-align: right;
      position: relative;
      margin-top:-3vw;
      padding-top: 0.3vw;
      opacity: 0; 
      //white-space: nowrap;
      line-height: 3vw;
      //transform: translateX(-1.5vw);
      -webkit-text-stroke-color: black;
      -webkit-text-stroke-width: 0.03vw;
      transition: margin-top 1s, opacity 0.3s, text-shadow 0.1s, transform 0.5s, color 0.1s; 
      text-shadow: 0.3vw 1px #ebc31d;
      
      
    }

    .menu-text{
        
        //transform: translateX(-1.5vw);

        text-shadow: 0.3vw 1px #f47748;
        padding-bottom: 1vw;
        transition: text-shadow 0.1s, transform 0.1s;
        margin-right: 1vw;
        
    }
    

    
}
.menu-title:hover .menu-item{
  margin-top: 0;
  opacity: 1;
  
}
.menu-title:active, .menu-title:hover .menu-text{
  text-shadow: 0.5vw 1px #f47748;
  transform: translateX(-1vw);
}

.menu-item:hover {

  text-shadow: 0.5vw 1px #ebc31d;
  transform: translateX(-1vw);
}
a, a:visited, a:active {
  
    .menu-item{
      color: #f3f3df; 

    }
  
}


.projects{
    z-index: 4;
    animation: 4.5s ease fade-in backwards;
    position: absolute;
    top: 0; 
    right: 0;
    margin-left: 10vw;
    margin-top: 0vh;
    padding-top: 15vh; 
    padding-right: 5vh;
    margin-right: 16vw;
    height: 105%; 
    width: 90vh;
    overflow: scroll;
    overflow-x: auto;
    
    
    text-align: right;
    transform: skew(-10deg, 0deg);

.project {
    
    
    margin-bottom: 10vw;
    color:#e9e7da;
    font-size: 10vw;
    mix-blend-mode: difference;
    
    display: block;
    
    -webkit-text-stroke: 0.2px;
    -webkit-text-stroke-color: black;
    text-shadow: 0.5vw 0.5vw #6693ab;

    font-family: "Roboto", sans-serif;

    font-style: normal;
    font-variant: normal;
    font-weight: 920;
    line-height: 1vw;
    transition: text-shadow 0.5s, transform 0.5s; 

    .detail {
      //position: absolute; 
      transform: none;
      transform: skew(10deg, 0deg);
      width: 2vw; 
      height: 2vw; 
      margin-left: 1vw; 
      margin-bottom: 1vw; 
     

    }
    
    
}

.project:hover{
  transform: translateX(-0.5vw) scale(1.05);
  text-shadow: 1vw 0.5vw #6693ab;
}



}
::-webkit-scrollbar {
  width: 0px; 
  background: transparent;
}
::-webkit-scrollbar-thumb {
  background: #FF0000;
}

.stuff {
    @extend %content;
    
    position: absolute; 
    left: 0; 
    bottom: 0;
    margin: -0.5vw;
   
    
    
}



.social {
  
  position: absolute;
  bottom: 0;
  right: 0;
  color: green;
  height: $h;
  width: $h;
  margin: 2vw;
  background-color: #ebc31d;
  animation: 4.5s ease fade-in backwards;
  z-index: 4;
}

.box2 {
    
    @extend %content; 
    position: absolute;
    top: 0;
    left: 0;
    height:100%;
    width: 50%;
    //background-image: url("https://miro.medium.com/max/1400/0*ScZSC40m756hGB4z");
    filter: invert(100%) sepia(100%) saturate(80%) hue-rotate(330deg)
  }
